import InfiniteUserPopup from "@components/alert/InfiniteUserPopup"
import { HariseninFooter } from "@components/harisenin"
import { Header } from "@components/header"
import Helmet from "@helpers/Helmet"
import { useScreenSize } from "@hooks/useScreenSize"
import { BannerInfo, DynamicHelmet } from "@interfaces/misc"
import { YoutubeVideo } from "@interfaces/youtube"
import { useAnalytics } from "@lib/context/AnalyticsContext"
import useOpenGraphImage from "@lib/helpers/open-graph"
import dynamic from "next/dynamic"
import React, { useEffect, useState } from "react"
import {
  HomeBanner,
  HomepageAlumnusRow,
  HomepageBenefit,
  HomepageCommunity,
  HomepageProgram,
  HomepageValue,
  NewTestimonialRow,
  PromoSlider,
  CompanyRow,
  YoutubeSlider,
  OurPrograms,
  CtaWebapp,
} from "./components/new-component"
import TestimonyVideoRow from "./components/new-component/TestimonyVideoRow"

const FloatingWhatsApp = dynamic(() => import("@harisenin/react-floating-whatsapp"), { ssr: false })

export interface LandingPageProps {
  youtubeVideo: YoutubeVideo[]
  bannerInfo: BannerInfo | null
  meta: DynamicHelmet | null
  localBusiness: DynamicHelmet | null
  collegeOrUniversity: DynamicHelmet | null
  videoTestimonies?: any
}

const LandingPage: React.FC<LandingPageProps> = ({
  youtubeVideo,
  bannerInfo,
  meta,
  collegeOrUniversity,
  localBusiness,
  videoTestimonies,
}) => {
  const { screenWidth } = useScreenSize()
  const { imageURL } = useOpenGraphImage() // <- This custom hook here!

  const [isTransparent, setIsTransparent] = useState(true)

  // const { analytics } = useAnalytics()

  // useEffect(() => {
  //   analytics.track("Product List Viewed", {
  //     products: [
  //       {
  //         product_id: "",
  //         name: "",
  //         affiliation: "PT. Karya Kaya Bahagia",
  //         coupon: "",
  //         discount: 0,
  //         currency: "IDR",
  //         brand: "",
  //         category: "All",
  //         variant: "",
  //         price: 0,
  //         quantity: 1,
  //         position: 0,
  //         item_list_name: "",
  //         item_list_id: "",
  //       },
  //     ],
  //     item_list_name: "",
  //     item_list_id: "",
  //   })
  // }, [])

  useEffect(() => {
    window.addEventListener("scroll", trackScrolling)

    return () => {
      window.removeEventListener("scroll", trackScrolling)
    }
  }, [])

  function isBottom(el: HTMLElement) {
    const eleTop = el.getBoundingClientRect().top

    return eleTop < 60
  }

  const trackScrolling = () => {
    const wrappedElement = document.getElementById("hp-banner")

    if (!wrappedElement) {
      return
    }

    const isBtm = isBottom(wrappedElement)

    if (isBtm) {
      setIsTransparent(false)
    } else {
      setIsTransparent(true)
    }
  }
  return (
    <>
      <FloatingWhatsApp
        phoneNumber="+6281312117711"
        accountName="Customer Services"
        allowClickAway
        notification
        notificationDelay={30000}
      />

      <InfiniteUserPopup/>

      <Helmet
        title={meta?.title}
        image={imageURL}
        description={meta?.description}
        isIndexed
        schemaMarkup={[localBusiness, collegeOrUniversity]}
        tags={meta?.tags}
      />

      <Header transparent={isTransparent} isNotSticky />

      <main className="font-inter">
        <HomeBanner info={bannerInfo} />
        <HomepageAlumnusRow />
        <HomepageBenefit />
        <PromoSlider windowWidth={screenWidth} />
        <HomepageProgram />
        <HomepageValue />
        <CompanyRow />
        <TestimonyVideoRow testimonies={videoTestimonies} screenWidth={screenWidth} />
        <NewTestimonialRow screenWidth={screenWidth} />
        <HomepageCommunity />
        <YoutubeSlider data={youtubeVideo} screenWidth={screenWidth} />
        <OurPrograms />
        <CtaWebapp />
      </main>

      <HariseninFooter />
    </>
  )
}

export default LandingPage
